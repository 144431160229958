<template>
  <div class="van-main">
    <van-search v-model="searchForm.pickUpNo" class="van-main-search" left-icon="" show-action placeholder="可输入提单号">
      <template #action>
        <div @click="onSearch">
          搜索
        </div>
      </template>
    </van-search>
    <div class="van-refresh-list">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-model="loading"
          :finished="finished"
          :finished-text="finishedText"
          @load="onLoad"
        >
          <van-cell-group v-for="item in list" :key="item.id" class="van-hairline-bottom">
            <van-cell class="van-cell-headline">
              <van-button v-if="Number(item.logisticsStatus) === 1" color="#4B7902" plain round size="small" class="bt-left">
                港
              </van-button>
              <van-button v-if="Number(item.logisticsStatus) === 0" color="#1373CC" plain round size="small" class="bt-left">
                途
              </van-button>
              <span>{{ `提单号:${utils.isEffectiveCommon(item.pickUpNo)}` }}</span>
              <p v-if="item.pushCustomsStatus === 1" class="lf">
                推送报关
              </p>
            </van-cell>
            <van-cell title="货物名称：" :value="item.logisticsPickUpInfoVoList[0].cargoName" />
            <van-cell title="合同号(PI/SC)：" :value="item.pi" />
            <van-cell title="柜号：">
              <span v-for="ids in item.logisticsPickUpInfoVoList" :key="ids.logisticsCabinetNoId"> {{ ids.cabinetNo }} </span>
            </van-cell>
            <van-cell title="柜数：" :value="item.logisticsPickUpInfoVoList.length " />
            <van-cell>
              <van-button class="lf" color="#1373CC" plain round size="small" @click="$router.push({ name: 'personalDetail', query: { id: item.id } })">
                查看详情
              </van-button>
            </van-cell>
          </van-cell-group>
        </van-list>
      </van-pull-refresh>
    </div>
    <van-row class="fixed-btn-bottom" gutter="20">
      <van-button type="info" color="#1373CC" block text="返回" @click="$router.back()" />
    </van-row>
  </div>
</template>

<script>
import { List, PullRefresh, Cell, CellGroup, Search, Button, Col, Row } from 'vant'
export default {
  components: {
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Search.name]: Search,
    [Button.name]: Button,
    [Col.name]: Col,
    [Row.name]: Row
  },
  data () {
    return {
      searchForm: {
        pickUpNo: '',
        dsEnterpriseId: this.$store.state.employeeInfo.userType === 4 || this.$store.state.employeeInfo.userType === 5 ? this.$store.state.employeeInfo.enterpriseId : '',
        pageNum: 1,
        pageSize: 10
      },
      finishedText: '没有更多了',
      page: 1,
      tableList: [],
      loading: false,
      finished: false,
      refreshing: false,
      list: []
    }
  },
  created () {
  },
  methods: {
    onSearch () {
      this.searchForm.pageNum = 1
      this.finishedText = '没有更多了'
      this.getList()
    },
    getList () {
      this.api.billofloading.pageList(this.utils.filterEmptyValue(this.searchForm)).then(result => {
        this.tableList = result.data.value
        if (this.tableList.pages === 0 && this._.isEmpty(this.tableList.list)) {
          this.list = []
          this.finishedText = '暂无数据'
          this.finished = true
        } else {
          if (this.tableList.pageNum === 1) {
            this.list = result.data.value.list
          } else {
            this.list = this.list.concat(result.data.value.list)
          }
          this.loading = false
          if (this.tableList.pageNum < this.tableList.pages) {
            this.searchForm.pageNum += 1
          } else {
            this.finished = true
          }
        }
      }).catch(() => {
        this.finished = true
        this.refreshing = false
        this.finishedText = '暂无数据'
      }).finally(() => {
        this.refreshing = false
      })
    },
    onLoad () {
      this.getList()
    },
    onRefresh () {
      // 清空列表数据
      this.finished = false
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true
      this.searchForm.pageNum = 1
      this.getList()
    }
  }
}
</script>
  <style lang="less" scoped>
    .bt-left{
      float: left !important;
      margin-right: 10%;
      color: #F59A23
    }
    .lf{
    float: right;
    color: #F46112
    }
  </style>
