var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "van-main" },
    [
      _c("van-search", {
        staticClass: "van-main-search",
        attrs: {
          "left-icon": "",
          "show-action": "",
          placeholder: "可输入提单号"
        },
        scopedSlots: _vm._u([
          {
            key: "action",
            fn: function() {
              return [
                _c("div", { on: { click: _vm.onSearch } }, [_vm._v(" 搜索 ")])
              ]
            },
            proxy: true
          }
        ]),
        model: {
          value: _vm.searchForm.pickUpNo,
          callback: function($$v) {
            _vm.$set(_vm.searchForm, "pickUpNo", $$v)
          },
          expression: "searchForm.pickUpNo"
        }
      }),
      _c(
        "div",
        { staticClass: "van-refresh-list" },
        [
          _c(
            "van-pull-refresh",
            {
              on: { refresh: _vm.onRefresh },
              model: {
                value: _vm.refreshing,
                callback: function($$v) {
                  _vm.refreshing = $$v
                },
                expression: "refreshing"
              }
            },
            [
              _c(
                "van-list",
                {
                  attrs: {
                    finished: _vm.finished,
                    "finished-text": _vm.finishedText
                  },
                  on: { load: _vm.onLoad },
                  model: {
                    value: _vm.loading,
                    callback: function($$v) {
                      _vm.loading = $$v
                    },
                    expression: "loading"
                  }
                },
                _vm._l(_vm.list, function(item) {
                  return _c(
                    "van-cell-group",
                    { key: item.id, staticClass: "van-hairline-bottom" },
                    [
                      _c(
                        "van-cell",
                        { staticClass: "van-cell-headline" },
                        [
                          Number(item.logisticsStatus) === 1
                            ? _c(
                                "van-button",
                                {
                                  staticClass: "bt-left",
                                  attrs: {
                                    color: "#4B7902",
                                    plain: "",
                                    round: "",
                                    size: "small"
                                  }
                                },
                                [_vm._v(" 港 ")]
                              )
                            : _vm._e(),
                          Number(item.logisticsStatus) === 0
                            ? _c(
                                "van-button",
                                {
                                  staticClass: "bt-left",
                                  attrs: {
                                    color: "#1373CC",
                                    plain: "",
                                    round: "",
                                    size: "small"
                                  }
                                },
                                [_vm._v(" 途 ")]
                              )
                            : _vm._e(),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                "提单号:" +
                                  _vm.utils.isEffectiveCommon(item.pickUpNo)
                              )
                            )
                          ]),
                          item.pushCustomsStatus === 1
                            ? _c("p", { staticClass: "lf" }, [
                                _vm._v(" 推送报关 ")
                              ])
                            : _vm._e()
                        ],
                        1
                      ),
                      _c("van-cell", {
                        attrs: {
                          title: "货物名称：",
                          value: item.logisticsPickUpInfoVoList[0].cargoName
                        }
                      }),
                      _c("van-cell", {
                        attrs: { title: "合同号(PI/SC)：", value: item.pi }
                      }),
                      _c(
                        "van-cell",
                        { attrs: { title: "柜号：" } },
                        _vm._l(item.logisticsPickUpInfoVoList, function(ids) {
                          return _c("span", { key: ids.logisticsCabinetNoId }, [
                            _vm._v(" " + _vm._s(ids.cabinetNo) + " ")
                          ])
                        }),
                        0
                      ),
                      _c("van-cell", {
                        attrs: {
                          title: "柜数：",
                          value: item.logisticsPickUpInfoVoList.length
                        }
                      }),
                      _c(
                        "van-cell",
                        [
                          _c(
                            "van-button",
                            {
                              staticClass: "lf",
                              attrs: {
                                color: "#1373CC",
                                plain: "",
                                round: "",
                                size: "small"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.$router.push({
                                    name: "personalDetail",
                                    query: { id: item.id }
                                  })
                                }
                              }
                            },
                            [_vm._v(" 查看详情 ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "van-row",
        { staticClass: "fixed-btn-bottom", attrs: { gutter: "20" } },
        [
          _c("van-button", {
            attrs: { type: "info", color: "#1373CC", block: "", text: "返回" },
            on: {
              click: function($event) {
                return _vm.$router.back()
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }